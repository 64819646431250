
























































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab4MechanismIntro',
  components: {FileUploadUiComponent, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        explanation: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      attachments: [],
      mechanism: [] as File[],
      rules: [(v: string) => v?.length <= 2500 || 'Max 2500 characters'],
      questions: [
        {
          en: 'Please upload a picture of your mechanism for the reduction of benzil.',
          fr: 'Veuillez télécharger une image de votre mécanisme de réduction du benzile.',
        },
        {
          en: 'Please provide a brief introduction for the experiment in the field below.',
          fr: "Veuillez fournir une brève introduction pour l'expérience dans le champ ci-dessous.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    fileUploadLabel(): string {
      return this.inputs.language === 'en' ? 'Upload your file' : 'Téléchargez vos fichiers';
    },
  },
});
